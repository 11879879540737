class Prayer extends HTMLElement {
    static get observedAttributes() { return ['data-author', 'data-date']; }
    constructor() {
        super();

        let content = this.innerHTML;

        this.innerHTML = '';

        this.classList.add('prayers-list__item')
        this.prayerContent = document.createElement('div');
        this.prayerContent.classList.add('prayers-list__item-content');
        let author = document.createElement('div');
        author.classList.add('prayers-list__item-author');
        this.prayerAuthor = document.createElement('div');
        this.prayerAuthor.classList.add('person');
        this.prayerDate = document.createElement('div');
        this.prayerDate.classList.add('date');

        author.append(this.prayerAuthor);
        author.append(this.prayerDate);

        this.append(this.prayerContent);
        this.append(author);

        if(this.getAttribute('data-author') !== null) {
            this.prayerAuthor.innerText = this.getAttribute('data-author');
        }
        if(this.getAttribute('data-date') !== null) {
            this.prayerDate.innerText = this.getAttribute('data-date');
        }
        if(content !== '') {
            this.prayerContent.innerHTML = content;
        }
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if(this.querySelector('.prayers-list__item-content') === null){
            let content = this.innerHTML;

            this.innerHTML = '';

            this.prayerContent = document.createElement('div');
            this.prayerContent.classList.add('prayers-list__item-content');
            let author = document.createElement('div');
            author.classList.add('prayers-list__item-author');
            this.prayerAuthor = document.createElement('div');
            this.prayerAuthor.classList.add('person');
            this.prayerDate = document.createElement('div');
            this.prayerDate.classList.add('date');

            author.append(this.prayerAuthor);
            author.append(this.prayerDate);

            this.append(this.prayerContent);
            this.append(author);

            if(content !== '') {
                this.prayerContent.innerHTML = content;
            }
        }
        if(this.getAttribute('data-author') !== null) {
            this.prayerAuthor.innerText = this.getAttribute('data-author');
        }
        if(this.getAttribute('data-date') !== null) {
            this.prayerDate.innerText = this.getAttribute('data-date');
        }
    }
}

let initElement = () => {
    customElements.define('netivo-prayer', Prayer);
};

export default {
    'init': initElement
};

