class Timeline {
    constructor(selector) {
        this.selector = selector;
        this.items = this.selector.querySelectorAll('[data-timeline-item]');

        this._showContent = this._showContent.bind(this);

        this.sides = this.selector.getAttribute('data-sides');
        let width = this.selector.offsetWidth;
        if(this.sides) {
            this.items.forEach(item => {
                let content = item.querySelector('[data-timeline-content]');
                content.style.width = (width/2 - 140) + 'px';
            });
        } else {
            this.items.forEach(item => {
                let content = item.querySelector('[data-timeline-content]');
                console.log(width, content);
                content.style.width = (width - 240) + 'px';
                let opener  = item.querySelector('[data-opener]');
                opener.addEventListener('click', this._showContent);
            });
        }

    }
    _showContent(event) {
        event.preventDefault();
        this.items.forEach(item => {
            let content = item.querySelector('[data-timeline-content]');
            let opener  = item.querySelector('[data-opener]');
            let dots = item.querySelector('[data-dots]');
            content.style.display = 'none';
            dots.style.display = 'none';
            opener.classList.remove('active');
        });
        let current = event.target.closest('[data-timeline-item]');
        let c_content = current.querySelector('[data-timeline-content]');
        let c_opener  = current.querySelector('[data-opener]');
        let c_dots = current.querySelector('[data-dots]');
        c_content.style.display = 'block';
        c_opener.classList.add('active');
        c_dots.style.display = 'flex';
    }

}

export default Timeline;