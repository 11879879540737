import Cookies from 'js-cookie';
class quantityInput {
    constructor(selector) {
        this.selector = selector;

        this.id = this.selector.getAttribute('data-id');


        this.decrease = this.decrease.bind(this);
        this.increase = this.increase.bind(this);
        this.changeInput = this.changeInput.bind(this);

        this.decreaseElement = this.selector.querySelector('[data-element="decrease"]');
        this.increaseElement = this.selector.querySelector('[data-element="increase"]');
        this.countElement = this.selector.querySelector('[data-element="count"]');

        this.decreaseElement.addEventListener('click', this.decrease);
        this.increaseElement.addEventListener('click', this.increase);
        this.countElement.addEventListener('change', this.changeInput);
    }

    decrease(event) {
        event.preventDefault();
        let value = this.countElement.value;
        value--;
        if(value < 0) value = 0;
        this.countElement.value = value;
        this.countElement.dispatchEvent(new Event('change'));
    }
    increase(event) {
        event.preventDefault();
        let value = this.countElement.value;
        value++;
        this.countElement.value = value;
        this.countElement.dispatchEvent(new Event('change'));
    }
    changeInput(event) {
        this.cart = Cookies.get('nt_cart');
        let cart = {};
        if(typeof this.cart !== 'undefined') {
            cart = JSON.parse(this.cart);
        }
        if(parseInt(event.target.value) === 0 && cart.hasOwnProperty(this.id)) {
            delete cart[this.id];
        } else {
            cart[this.id] = event.target.value;
        }
        this.cart = JSON.stringify(cart);
        console.log(this.cart);
        Cookies.set('nt_cart', this.cart, {SameSite: 'lax'});
    }
}

export default quantityInput;