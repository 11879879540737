import { ModalElement } from "./ModalElement";

class PublicationLightbox extends ModalElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        this.loaderContainer = document.createElement('div');
        this.loaderContainer.classList.add('publication-lightbox__loading');
        this.loaderContainer.setAttribute('aria-label', 'Ładowanie informacji');
        let loader = document.createElement('div');
        loader.classList.add('loader');
        let bounce1 = document.createElement('div');
        let bounce2 = document.createElement('div');
        bounce1.classList.add('double-bounce1');
        bounce2.classList.add('double-bounce2');

        loader.append(bounce1);
        loader.append(bounce2);
        this.loaderContainer.append(loader);

        this.container.append(this.loaderContainer);
    }

    showError(message) {
        let error_message = document.createElement('div');
        error_message.classList.add('loader__error');
        error_message.innerText = message;
        this.loaderContainer.innerHTML = '';
        this.loaderContainer.append(error_message);
    }

    showPublication(data) {
        let image_cont = document.createElement('div');
        image_cont.classList.add('publication-lightbox__image-box');
        if(data.hasOwnProperty('_embedded')) {
            let image = document.createElement('img');
            image.classList.add('publication-lightbox__image');
            image.src = data['_embedded']['wp:featuredmedia'][0]['source_url'];
            image.setAttribute('alt', data['_embedded']['wp:featuredmedia'][0]['alt_text']);
            image_cont.append(image);
        }
        let data_content = document.createElement('div');
        data_content.classList.add('publication-lightbox__content');
        let title = document.createElement('div');
        title.classList.add('publication-lightbox__title');
        title.id = 'modal-id'
        title.innerHTML = data.title.rendered;
        let price = document.createElement('div');
        price.classList.add('publication-lightbox__price');
        price.innerHTML = (data.meta._price !== '') ? data.meta._price + 'PLN' : '';
        let about = document.createElement('div');
        about.classList.add('publication-lightbox__about');
        if(data.meta.hasOwnProperty('_author') && data.meta._author !== '') {
            let author = document.createElement('p');
            author.classList.add('author');
            author.innerHTML = 'Autor: ' + data.meta._author;
            about.append(author);
        }
        if(data.meta.hasOwnProperty('_language') && data.meta._language !== '') {
            let language = document.createElement('p');
            language.classList.add('language');
            language.innerHTML = 'Jęzuk: ' + data.meta._language;
            about.append(language);
        }
        if(data.meta.hasOwnProperty('_pages') && data.meta._pages !== '') {
            let pages = document.createElement('p');
            pages.classList.add('pages');
            pages.innerHTML = 'Liczba stron: ' + data.meta._pages;
            about.append(pages);
        }
        if(data.meta.hasOwnProperty('_time') && data.meta._time !== '') {
            let time = document.createElement('p');
            time.classList.add('time');
            time.innerHTML = 'Długość: ' + data.meta._time;
            about.append(time);
        }
        if(data.meta.hasOwnProperty('_isbn') && data.meta._isbn !== '') {
            let isbn = document.createElement('p');
            isbn.classList.add('isbn');
            isbn.innerHTML = 'ISBN: ' + data.meta._isbn;
            about.append(isbn);
        }

        let description = document.createElement('div');
        description.classList.add('publication-lightbox__description');
        description.innerHTML = data.content.rendered;

        let icons = document.createElement('div');
        icons.classList.add('publication-lightbox__icons');
        if(data.meta.hasOwnProperty('_free') && data.meta._free !== '') {
            let pdf = document.createElement('a');
            pdf.classList.add('publication-lightbox__pdf');
            pdf.setAttribute('href', data.meta._free);
            pdf.setAttribute('aria-label', 'Pobierz PDF');
            pdf.setAttribute('role', 'button');
            let ico = document.createElement('img');
            ico.classList.add('publication-lightbox__icon');
            ico.src = '/wp-content/themes/stutthof/dist/images/pdf-icon.png';
            ico.setAttribute('role', 'none');
            pdf.append(ico);
            icons.append(pdf);
        }
        if(data.meta.hasOwnProperty('_price') && data.meta._price !== '') {
            let shop = document.createElement('a');
            shop.classList.add('publication-lightbox__pdf');
            shop.setAttribute('href', '/ksiegarnia');
            shop.setAttribute('aria-label', 'Przejdź do księgarni');
            shop.setAttribute('role', 'button');
            let ico_s = document.createElement('img');
            ico_s.classList.add('publication-lightbox__icon');
            ico_s.src = '/wp-content/themes/stutthof/dist/images/book-icon.png';
            ico_s.setAttribute('role', 'none');
            shop.append(ico_s);
            icons.append(shop);
        }

        data_content.append(title);
        data_content.append(price);
        data_content.append(about);
        data_content.append(description);
        data_content.append(icons)

        this.container.append(image_cont);
        this.container.append(data_content);

        this.loaderContainer.remove();
    }

    openModal() {
        super.openModal();
    }
}

let initElement = () => {
    customElements.define('publication-lightbox', PublicationLightbox);
};

export default {
    'init': initElement
};

