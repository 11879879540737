import Cookies from 'js-cookie';
class Wai {
    constructor(element) {
        this.element = element;

        let font = document.body.style.getPropertyValue('--default-font-size');
        if(font === '') {
            font = Cookies.get('nt_font_size');
        }
        if(typeof font === 'undefined') font = '16px';

        this.fontSize = parseInt(font);
        this.contrast = null;

        this.defaultFont = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--default-font-size'));

        this.fontIncrease = this.element.querySelector('[data-element="font-increase"]');
        this.fontDefault = this.element.querySelector('[data-element="font-default"]');
        this.fontDescrease = this.element.querySelector('[data-element="font-decrease"]');

        this.contrastDefault = this.element.querySelector('[data-element="contrast-default"]');
        this.contrastHigh = this.element.querySelector('[data-element="contrast-high"]');
        this.contrastNegative = this.element.querySelector('[data-element="contrast-negative"]');

        this.setDefaultFont = this.setDefaultFont.bind(this);
        this.decreaseFont = this.decreaseFont.bind(this);
        this.increaseFont = this.increaseFont.bind(this);

        this.setDefaultContrast = this.setDefaultContrast.bind(this);
        this.setHighContrast = this.setHighContrast.bind(this);
        this.setNegativeContrast = this.setNegativeContrast.bind(this);

        this.fontDefault.addEventListener('click', this.setDefaultFont);
        this.fontIncrease.addEventListener('click', this.increaseFont);
        this.fontDescrease.addEventListener('click', this.decreaseFont);

        this.contrastDefault.addEventListener('click', this.setDefaultContrast);
        this.contrastHigh.addEventListener('click', this.setHighContrast);
        this.contrastNegative.addEventListener('click', this.setNegativeContrast);
    }

    setDefaultFont(event) {
        event.preventDefault();
        document.body.style.removeProperty('--default-font-size');
        Cookies.remove('nt_font_size', {path: '/'});
        this.fontSize = null;
    }
    increaseFont(event) {
        event.preventDefault();
        if(this.fontSize == null) this.fontSize = this.defaultFont;
        if(this.fontSize < 22) {
            this.fontSize++;
            document.body.style.setProperty('--default-font-size', this.fontSize+'px');
            Cookies.set('nt_font_size', this.fontSize+'px', {SameSite: 'lax'});
        }
    }
    decreaseFont(event) {
        event.preventDefault();
        if(this.fontSize == null) this.fontSize = this.defaultFont;
        if(this.fontSize > 12) {
            this.fontSize--;
            document.body.style.setProperty('--default-font-size', this.fontSize+'px');
            Cookies.set('nt_font_size', this.fontSize+'px', {SameSite: 'lax'});
        }
    }

    setDefaultContrast(event) {
        event.preventDefault();
        Cookies.remove('nt_contrast');
        document.body.style.removeProperty('--main-background');
        document.body.style.removeProperty('--footer-background');
        document.body.style.removeProperty('--default-font-color');
        document.body.style.removeProperty('--header-text-color');
        document.body.style.removeProperty('--footer-text-color');
        //@todo remove body props
        this.contrast = null;
    }
    setHighContrast(event) {
        event.preventDefault();
        if(this.contrast !== 'high') {
            this.contrast = 'high';
            //@todo set body props
            Cookies.set('nt_contrast', 'high', {SameSite: 'lax'});
        }
    }
    setNegativeContrast(event) {
        event.preventDefault();
        if(this.contrast !== 'negative') {
            this.contrast = 'negative';
            document.body.style.setProperty('--main-background', '#000000');
            document.body.style.setProperty('--footer-background', '#000000');
            document.body.style.setProperty('--default-font-color', '#ffff00');
            document.body.style.setProperty('--header-text-color', '#ffff00');
            document.body.style.setProperty('--footer-text-color', '#ffff00');
            document.body.style.setProperty('--hyperlink-hover-color', '#ffff00');
            //@todo set body props
            Cookies.set('nt_contrast', 'negative', {SameSite: 'lax'});
        }
    }
}

export default Wai;