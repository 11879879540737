import Wai from "../wai";
import elModal from '../helpers/ModalElement';
import elPubLight from '../helpers/PublicationLightbox';
import elPrayer from '../helpers/Prayer';
import elIntIcon from '../helpers/InteractiveIcon';
import elTimLes from '../helpers/TimelineLesson';
import publicationDetails from "../publicationDetails";
import Timeline from "../timeline";
import {Form, initMap} from "@netivo/base-scripts";
import QuantityInput from "../quantityInput";
import Splide from "@splidejs/splide";
import imageMapResize from "image-map-resizer";
import GLightbox from 'glightbox/src/js/glightbox';

elModal.init();
elPubLight.init();
elPrayer.init(); 
elIntIcon.init();
elTimLes.init();

window.initMap = initMap;

let hamburger = document.querySelector('.js-hamburger');
let menu = document.querySelector('#mobile-menu');
let mask = document.querySelector('#mask');

const toggleMenu = (e) => {
    e.preventDefault();
    hamburger.classList.toggle('active');
    menu.classList.toggle('toggled');
    mask.classList.toggle('toggled');
    if (menu.classList.contains('toggled')) {
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
        menu.focus();
        menu.setAttribute('aria-hidden', false);
    } else {
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
        menu.setAttribute('aria-hidden', true);
    }
}

hamburger.addEventListener('click', toggleMenu);
mask.addEventListener('click', toggleMenu);

let waiContainer = document.querySelector('.js-wai');
if(waiContainer !== null) {
    new Wai(waiContainer);
}

let publicationLink = document.querySelectorAll('.js-publication');
if(publicationLink.length > 0) {
    publicationLink.forEach(element => {
        new publicationDetails(element);
    })
}

let timeline = document.querySelector('.js-timeline');
if(timeline !== null) {
    new Timeline(timeline);
}

let quantityInputs = document.querySelectorAll('.js-quantity-input');
if(quantityInputs.length > 0){
    quantityInputs.forEach(qi => {
        new QuantityInput(qi);
    })
}

let gallery = document.querySelectorAll('.js-gallery');
if(gallery.length > 0) {
    gallery.forEach(gal => {
        let main = new Splide( gal.querySelector('[data-element="main-gallery"]'), {
            type       : 'fade',
            pagination : false,
            arrows     : false,
            autoHeight : true,
            fixedHeight: 500,
            cover: false
        } );

        let thumbnails = new Splide( gal.querySelector('[data-element="thumbnails"]'), {
            rewind          : true,
            fixedWidth      : 100,
            fixedHeight     : 100,
            isNavigation    : true,
            gap             : 10,
            focus           : 'center',
            pagination      : false,
            cover           : true,
            dragMinThreshold: {
                mouse: 4,
                touch: 10,
            },
            breakpoints : {
                640: {
                    fixedWidth  : 80,
                    fixedHeight : 80,
                },
            },
        } );

        main.sync( thumbnails );
        main.mount();
        thumbnails.mount();
    });
}

let prayerForm = document.querySelector('.js-prayer-form');
if(prayerForm !== null) {
    let recaptcha_key = prayerForm.getAttribute('data-recaptcha');
    new Form(prayerForm, {
        action: '/wp-admin/admin-ajax.php',
        data: el => {
            return new Promise((resolve, reject) => {
                let data = {
                    action: 'nt_send_form_prayer',
                    first_name: '',
                    prayer: ''
                };

                let form_first_name = el.querySelector('[name="first_name"]'),
                    form_prayer = el.querySelector('[name="prayer"]');

                if (form_first_name !== null) data.first_name = form_first_name.value;
                if (form_prayer !== null) data.prayer = form_prayer.value;
                // grecaptcha.ready(function() {
                //     grecaptcha.execute(recaptcha_key, {action: 'application'}).then(function (token) {
                //         data.recaptcha = token;
                //         resolve(data);
                //     });
                // });
                resolve(data);
            });
        },
        success: (el, response) => {
            let form_body = prayerForm.querySelector('[data-element="body"]'),
                form_message = prayerForm.querySelector('[data-element="message"]'),
                form_button = prayerForm.querySelector('[data-element="button"]');
            form_message.innerText = response.message;
            form_body.style.display = 'none';
            form_button.style.display = 'none';
            form_message.style.display = 'block';
            form_message.classList.add('success');

            let prayer = document.createElement('div', {is:'netivo-prayer'});
            prayer.innerHTML = response.prayer.content;
            prayer.setAttribute('data-author', response.prayer.author);
            prayer.setAttribute('data-date', response.prayer.date);

            let list = document.querySelector('.js-prayers-list');
            list.prepend(prayer);

            if(window.gtag !== undefined){
                window.gtag('event', 'application');
            }

        },
        error: (el, response) => {
            let form_body = prayerForm.querySelector('[data-element="body"]'),
                form_message = prayerForm.querySelector('[data-element="message"]'),
                form_button = prayerForm.querySelector('[data-element="button"]');
            form_message.innerText = response.message;
            form_body.style.display = 'none';
            form_button.style.display = 'none';
            form_message.style.display = 'block';
            form_message.classList.add('error');
        }
    });
}

let prisonerForm = document.querySelector('.js-prisoner-form');
if(prisonerForm !== null) {
    new Form(prisonerForm, {
        action: '/wp-admin/admin-ajax.php',
        beforeSubmit: el => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.style.display = 'block';
            let results = document.querySelector('.js-prisoner-results');
            results.innerHTML = '';
            return true;
        },
        data: el => {
            return new Promise((resolve, reject) => {
                let data = {
                    nazwisko: '',
                    imie: '',
                    nr_obozowy: '',
                    data_ur: '',
                    miejsce_ur: '',
                    miejsce_zam: '',
                    zawod: '',
                    action: 'nt_former_prisoners'
                };

                let form_nazwisko = el.querySelector('[name="nazwisko"]'),
                    form_imie = el.querySelector('[name="imie"]'),
                    form_nr_obozowy = el.querySelector('[name="nr_obozowy"]'),
                    form_data_ur = el.querySelector('[name="data_ur"]'),
                    form_miejsce_ur = el.querySelector('[name="miejsce_ur"]'),
                    form_miejsce_zam = el.querySelector('[name="miejsce_zam"]'),
                    form_zawod = el.querySelector('[name="zawod"]');

                if (form_nazwisko !== null) data.nazwisko = form_nazwisko.value;
                if (form_imie !== null) data.imie = form_imie.value;
                if (form_nr_obozowy !== null) data.nr_obozowy = form_nr_obozowy.value;
                if (form_data_ur !== null) data.data_ur = form_data_ur.value;
                if (form_miejsce_ur !== null) data.miejsce_ur = form_miejsce_ur.value;
                if (form_miejsce_zam !== null) data.miejsce_zam = form_miejsce_zam.value;
                if (form_zawod !== null) data.zawod = form_zawod.value;
                // grecaptcha.ready(function() {
                //     grecaptcha.execute(recaptcha_key, {action: 'application'}).then(function (token) {
                //         data.recaptcha = token;
                //         resolve(data);
                //     });
                // });
                resolve(data);
            });
        },
        success: (el, response) => {
            if(response.length > 0){
                let results = document.querySelector('.js-prisoner-results');
                results.innerHTML = '';
                let title = document.createElement('h4');
                title.classList.add('title');
                title.classList.add('title--small');
                title.classList.add('title--no-border');
                title.innerText = 'Lista wyników';
                let info = document.createElement('p');
                info.innerText = 'Znaleziono ' + response.length + ' więźniów';
                results.append(title);
                results.append(info);

                response.forEach(prisoner => {
                    let prisEl = document.createElement('blockquote');
                    prisEl.classList.add('db-result');
                    if(prisoner.hasOwnProperty('nazwisko')) prisEl.innerHTML += '<p><strong>Nazwisko: </strong>'+ prisoner.nazwisko+'</p>';
                    if(prisoner.hasOwnProperty('nazwisko_ss')) prisEl.innerHTML += '<p><strong>Nazwisko: </strong>'+ prisoner.nazwisko_ss+'</p>';
                    if(prisoner.hasOwnProperty('imie')) prisEl.innerHTML += '<p><strong>Imię: </strong>'+ prisoner.imie+'</p>';
                    if(prisoner.hasOwnProperty('nr')) prisEl.innerHTML += '<p><strong>Nr obozowy: </strong>'+ prisoner.nr+'</p>';
                    if(prisoner.hasOwnProperty('data_urodzenia')) prisEl.innerHTML += '<p><strong>Data urodzenia: </strong>'+ prisoner.data_urodzenia+'</p>';
                    if(prisoner.hasOwnProperty('data_zgonu')) prisEl.innerHTML += '<p><strong>Data zgonu: </strong>'+ prisoner.data_zgonu+'</p>';
                    if(prisoner.hasOwnProperty('miejsce_urodzenia')) prisEl.innerHTML += '<p><strong>Miejsce urodzenia: </strong>'+ prisoner.miejsce_urodzenia+'</p>';
                    if(prisoner.hasOwnProperty('miejsce_zamieszkania')) prisEl.innerHTML += '<p><strong>Miejsce zamieszkania: </strong>'+ prisoner.miejsce_zamieszkania+'</p>';
                    if(prisoner.hasOwnProperty('narodowosc')) prisEl.innerHTML += '<p><strong>Narodowość: </strong>'+ prisoner.narodowosc+'</p>';
                    if(prisoner.hasOwnProperty('zawod')) prisEl.innerHTML += '<p><strong>Zawód: </strong>'+ prisoner.zawod+'</p>';
                    if(prisoner.hasOwnProperty('sygnatura')) prisEl.innerHTML += '<p><strong>Sygnatura akt archiwalnych: </strong>'+ prisoner.sygnatura+'</p>';
                    if(prisoner.hasOwnProperty('sygnatura2')) prisEl.innerHTML += '<p><strong>Sygnatura: </strong>'+ prisoner.sygnatura2+'</p>';
                    if(prisoner.hasOwnProperty('plec')) prisEl.innerHTML += '<p><strong>Płeć: </strong>'+ prisoner.plec+'</p>';
                    if(prisoner.hasOwnProperty('uwagi')) prisEl.innerHTML += '<p><strong>Uwagi: </strong>'+ prisoner.uwagi+'</p>';
                    if(prisoner.hasOwnProperty('dbname')) prisEl.innerHTML += '<p><strong>Źródło: </strong>'+ prisoner.dbname+'</p>';
                    results.append(prisEl);
                });
                results.style.display = 'block';
            }
            let loader = el.querySelector('[data-element="loader"]');
            loader.style.display = 'none';
        },
        error: (el, response) => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.style.display = 'none';
            let results = document.querySelector('.js-prisoner-results');
            results.innerHTML = '';
            let title = document.createElement('h4');
            title.classList.add('title');
            title.classList.add('title--small');
            title.classList.add('title--no-border');
            title.classList.add('title--error');
            title.innerText = response.message;
            console.log(response);
            results.append(title);
        }
    });
}
let iconForm = document.querySelector('.js-iconographic-collections');
if(iconForm !== null) {
    new Form(iconForm, {
        action: '/wp-admin/admin-ajax.php',
        beforeSubmit: el => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.style.display = 'block';
            let results = document.querySelector('.js-icons-results');
            results.innerHTML = '';
            return true;
        },
        data: el => {
            return new Promise((resolve, reject) => {
                let data = {
                    hg: '',
                    hp: '',
                    opis: '',
                    autor: '',
                    sygnatura: '',
                    action: 'nt_iconographic_collections'
                };

                let form_hg = el.querySelector('[name="haslo_glowne"]'),
                    form_hp = el.querySelector('[name="haslo_podrzedne"]'),
                    form_opis = el.querySelector('[name="opis"]'),
                    form_autor = el.querySelector('[name="autor"]'),
                    form_sygnatura = el.querySelector('[name="sygnatura"]');

                if (form_hg !== null) data.hg = form_hg.value;
                if (form_hp !== null) data.hp = form_hp.value;
                if (form_opis !== null) data.opis = form_opis.value;
                if (form_autor !== null) data.autor = form_autor.value;
                if (form_sygnatura !== null) data.sygnatura = form_sygnatura.value;
                // grecaptcha.ready(function() {
                //     grecaptcha.execute(recaptcha_key, {action: 'application'}).then(function (token) {
                //         data.recaptcha = token;
                //         resolve(data);
                //     });
                // });
                resolve(data);
            });
        },
        success: (el, response) => {
            if(response.length > 0){
                let results = document.querySelector('.js-icons-results');
                results.innerHTML = '';
                let title = document.createElement('h4');
                title.classList.add('title');
                title.classList.add('title--small');
                title.classList.add('title--no-border');
                title.innerText = 'Lista wyników';
                let info = document.createElement('p');
                info.innerText = 'Znaleziono ' + response.length + ' pozycji';
                results.append(title);
                results.append(info);

                response.forEach(icon => {
                    let iconEl = document.createElement('blockquote');
                    iconEl.classList.add('db-result');
                    if(icon.hasOwnProperty('sygnatura')) iconEl.innerHTML += '<p><strong>Sygnatura: </strong>'+ icon.sygnatura+'</p>';
                    if(icon.hasOwnProperty('hg')) iconEl.innerHTML += '<p><strong>Hasło głowne: </strong>'+ icon.hg+'</p>';
                    if(icon.hasOwnProperty('hp')) iconEl.innerHTML += '<p><strong>Hasło podrzędne: </strong>'+ icon.hp+'</p>';
                    if(icon.hasOwnProperty('opis')) iconEl.innerHTML += '<p><strong>Opis: </strong>'+ icon.opis+'</p>';
                    if(icon.hasOwnProperty('autor')) iconEl.innerHTML += '<p><strong>Autor: </strong>'+ icon.autor+'</p>';
                    if(icon.hasOwnProperty('sygnatura')) iconEl.innerHTML += '<p><strong>Sygnatura: </strong>'+ icon.sygnatura+'</p>';
                    results.append(iconEl);
                });
                results.style.display = 'block';
            }
            let loader = el.querySelector('[data-element="loader"]');
            loader.style.display = 'none';
        },
        error: (el, response) => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.style.display = 'none';
            let results = document.querySelector('.js-icons-results');
            results.innerHTML = '';
            let title = document.createElement('h4');
            title.classList.add('title');
            title.classList.add('title--small');
            title.classList.add('title--no-border');
            title.classList.add('title--error');
            title.innerText = response.message;
            console.log(response);
            results.append(title);
        }
    });
}
let classesForm = document.querySelector('.js-lesson-form');
if(classesForm !== null) {
    new Form(classesForm, {
        action: '/wp-admin/admin-ajax.php',
        beforeSubmit: el => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.querySelector('.loader').style.display = 'block';
            let error = loader.querySelector('.loader-error');
            if(error !== null) error.remove();
            loader.style.display = 'flex';
            return true;
        },
        data: el => {
            return new Promise((resolve, reject) => {
                let data = {
                    educators: '',
                    classesDate: '',
                    classesTime: '',
                    peopleAmount: '',
                    topic: '',
                    email: '',
                    about: '',
                    action: 'nt_classes'
                };

                let form_educators = el.querySelector('[name="educators"]:checked'),
                    form_classes_date = el.querySelector('[name="classes_date"]'),
                    form_classes_time = el.querySelector('[name="classes_time"]'),
                    form_people_amount = el.querySelector('[name="people_amount"]'),
                    form_topic = el.querySelector('[name="topic"]'),
                    form_email = el.querySelector('[name="email"]'),
                    form_about = el.querySelector('[name="about"]');

                if (form_educators !== null) data.educators = form_educators.value;
                if (form_classes_date !== null) data.classesDate = form_classes_date.value;
                if (form_classes_time !== null) data.classesTime = form_classes_time.value;
                if (form_people_amount !== null) data.peopleAmount = form_people_amount.value;
                if (form_topic !== null) data.topic = form_topic.value;
                if (form_email !== null) data.email = form_email.value;
                if (form_about !== null) data.about = form_about.value;
                // grecaptcha.ready(function() {
                //     grecaptcha.execute(recaptcha_key, {action: 'application'}).then(function (token) {
                //         data.recaptcha = token;
                //         resolve(data);
                //     });
                // });
                resolve(data);
            });
        },
        success: (el, response) => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.querySelector('.loader').remove();
            loader.innerHTML = '<div class="loader-success">Udało się pomyślnie wysłać formularz.</div>';
        },
        error: (el, response) => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.querySelector('.loader').style.display = 'none';
            loader.innerHTML += '<div class="loader-error">'+response.error+'</div>';
            window.setTimeout(() => {
                loader.style.display = 'none';
            }, 3000);
        }
    });
}
let orderForm = document.querySelector('.js-order-form');
if(orderForm !== null) {
    new Form(orderForm, {
        action: '/wp-admin/admin-ajax.php',
        beforeSubmit: el => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.querySelector('.loader').style.display = 'block';
            let error = loader.querySelector('.loader-error');
            if(error !== null) error.remove();
            loader.style.display = 'flex';
            return true;
        },
        data: el => {
            return new Promise((resolve, reject) => {
                let data = {
                    cart: '',
                    companyName: '',
                    name: '',
                    surname: '',
                    adress: '',
                    adressContinued: '',
                    city: '',
                    zipCode: '',
                    country: '',
                    phone: '',
                    nip: '',
                    email: '',
                    additionalInformation: '',
                    action: 'nt_order'
                };

                let cart = [];

                let cartEl = el.querySelectorAll('.js-quantity-input');
                cartEl.forEach(e => {
                    let cnt = e.querySelector('[data-element="count"]');
                    if(cnt.value > 0) {
                        cart.push(e.getAttribute('data-id') + ':' + cnt.value);
                    }
                });
                data.cart = cart.join(',');

                let form_companyName = el.querySelector('[name="company_name"]'),
                    form_name = el.querySelector('[name="name"]'),
                    form_surname = el.querySelector('[name="surname"]'),
                    form_adress = el.querySelector('[name="adress"]'),
                    form_adressContinued = el.querySelector('[name="adress_continued"]'),
                    form_city = el.querySelector('[name="city"]'),
                    form_zipCode = el.querySelector('[name="zip_code"]'),
                    form_country = el.querySelector('[name="country"]'),
                    form_phone = el.querySelector('[name="phone"]'),
                    form_nip = el.querySelector('[name="nip"]'),
                    form_email = el.querySelector('[name="email"]'),
                    form_additionalInformation = el.querySelector('[name="additional_information"]');

                if (form_companyName !== null) data.companyName = form_companyName.value;
                if (form_name !== null) data.name = form_name.value;
                if (form_surname !== null) data.surname = form_surname.value;
                if (form_adress !== null) data.adress = form_adress.value;
                if (form_adressContinued !== null) data.adressContinued = form_adressContinued.value;
                if (form_city !== null) data.city = form_city.value;
                if (form_zipCode !== null) data.zipCode = form_zipCode.value;
                if (form_country !== null) data.country = form_country.value;
                if (form_phone !== null) data.phone = form_phone.value;
                if (form_nip !== null) data.nip = form_nip.value;
                if (form_email !== null) data.email = form_email.value;
                if (form_additionalInformation !== null) data.additionalInformation = form_additionalInformation.value;
                // grecaptcha.ready(function() {
                //     grecaptcha.execute(recaptcha_key, {action: 'application'}).then(function (token) {
                //         data.recaptcha = token;
                //         resolve(data);
                //     });
                // });
                resolve(data);
            });
        },
        success: (el, response) => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.querySelector('.loader').remove();
            loader.innerHTML = '<div class="loader-success">Udało się pomyślnie wysłać formularz.</div>';
        },
        error: (el, response) => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.querySelector('.loader').style.display = 'none';
            loader.innerHTML += '<div class="loader-error">'+response.error+'</div>';
            window.setTimeout(() => {
                loader.style.display = 'none';
            }, 3000);
        }
    });
}


let lessonForm = document.querySelector('.js-contact-form');
if(lessonForm !== null) {
    new Form(lessonForm, {
        action: '/wp-admin/admin-ajax.php',
        beforeSubmit: el => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.querySelector('.loader').style.display = 'block';
            let error = loader.querySelector('.loader-error');
            if(error !== null) error.remove();
            loader.style.display = 'flex';
            return true;
        },
        data: el => {
            return new Promise((resolve, reject) => {
                let data = {
                    firstName: '',
                    lastName: '',
                    schoolName: '',
                    email: '',
                    message: '',
                    lesson: '',
                    action: 'nt_lesson_contact'
                };

                let form_first_name = el.querySelector('[name="first_name"]'),
                    form_last_name = el.querySelector('[name="last_name"]'),
                    form_school_name = el.querySelector('[name="school_name"]'),
                    form_email = el.querySelector('[name="email"]'),
                    form_lesson = el.querySelector('[name="lesson"]'),
                    form_message = el.querySelector('[name="message"]');

                if (form_first_name !== null) data.firstName = form_first_name.value;
                if (form_last_name !== null) data.lastName = form_last_name.value;
                if (form_school_name !== null) data.schoolName = form_school_name.value;
                if (form_email !== null) data.email = form_email.value;
                if (form_message !== null) data.message = form_message.value;
                if (form_lesson !== null) data.lesson = form_lesson.value;
                // grecaptcha.ready(function() {
                //     grecaptcha.execute(recaptcha_key, {action: 'application'}).then(function (token) {
                //         data.recaptcha = token;
                //         resolve(data);
                //     });
                // });
                resolve(data);
            });
        },
        success: (el, response) => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.querySelector('.loader').remove();
            loader.innerHTML = '<div class="loader-success">Udało się pomyślnie wysłać formularz.</div>';
        },
        error: (el, response) => {
            let loader = el.querySelector('[data-element="loader"]');
            loader.querySelector('.loader').style.display = 'none';
            loader.innerHTML += '<div class="loader-error">'+response.message+'</div>';
            window.setTimeout(() => {
                loader.style.display = 'none';
            }, 3000);
        }
    });
}

let imageMaps = document.querySelector('[data-element="image-map"]');
if(imageMaps !== null) {
    imageMapResize(imageMaps);
}

new GLightbox();