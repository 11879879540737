export class ModalElement extends HTMLElement {
    constructor() {
        super();

        this.isOpen = false;
        this.container = document.createElement('div');

        this.labeledBy = null;

        this.styles = document.createElement('style');
        this.styles.textContent = `.loader {
  width: 75px;
  height: 75px;
  position: relative;
}

.loader__error {
  //position: absolute;
  //left: 50%;
  //top: 50%;
  //transform: translate(-50%, -50%);
  font-size: 18px;
  color: #ffffff;
}

.loader-error {
  //position: absolute;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #cc2931;
  border: 1px solid #cc2931;
  padding: 20px;
  background-color: #f2bfc2;
  font-weight: 600;
  //left: 50%;
  //top: 50%;
  //transform: translate(-50%, -50%);
  z-index: 50000;
}

.loader-success {
  //position: absolute;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
  color: #228B22;
  border: 1px solid #228B22;
  padding: 20px;
  background-color: #c2f0c2;
  font-weight: 600;
  //left: 50%;
 // top: 50%;
  //transform: translate(-50%, -50%);
  z-index: 50000;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@media screen and (max-width: 1366px) {
  .loader {
    height: 60px;
    width: 60px;
  }
}
@media screen and (max-width: 800px) {
  .loader {
    height: 40px;
    width: 40px;
  }
} 
.publication-lightbox {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.publication-lightbox__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
}
.publication-lightbox__close-button {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  font-size: 40px;
  color: #ffffff;
  background-color: transparent;
  border: none;
  padding: 30px;
  z-index: 500;
}
.publication-lightbox__close-button .first-line {
  width: 50px;
  height: 5px;
  background-color: #ffffff;
  display: block;
  margin: 8px auto;
  transform: translateY(7px) rotate(45deg);
}
.publication-lightbox__close-button .second-line {
  width: 50px;
  height: 5px;
  background-color: #ffffff;
  display: block;
  margin: 8px auto;
  transform: translateY(-6px) rotate(-45deg);
}
.publication-lightbox__container {
  width: 50%;
  height: auto;
  display: flex;
  background: var(--footer-background);
  padding: 30px;
  max-height: 80vh;
  overflow-y: auto;
  box-sizing: border-box;
}
.publication-lightbox__image-box {
  min-width: 250px;
  height: auto;
  margin-right: 45px;
}
.publication-lightbox__image {
  width: 250px;
  height: auto;
  max-height: 500px;
  display: block;
}
.publication-lightbox__content {
  display: block;
  flex-direction: column;
}
.publication-lightbox__title {
  color: var(--default-font-color);
  font-size: 1.2em;
  line-height: 1.4em;
  font-weight: 600;
}
.publication-lightbox__price {
  color: var(--default-font-color);
  font-size: 1.1em;
  line-height: 1.4em;
}
.publication-lightbox__about {
  color: var(--default-font-color);
  font-size: 1em;
  line-height: 1.5em;
  margin: 30px 0;
}
.publication-lightbox__about p {
  margin: 0;
}
.publication-lightbox__description {
  color: var(--default-font-color);
  font-size: 1em;
  line-height: 1.5em;
}
.publication-lightbox__icons {
  display: flex;
  margin-top: 30px;
}
.publication-lightbox__shop {
  margin-right: 30px;
}
.publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__container {
  display: flex;
  flex-direction: column;
}
.publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__image-box {
  width: 50%;
  position: relative;
}
.publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__image {
  width: 100%;
}
.publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__title {
  width: calc(100% - 50px);
  margin-top: 20px;
}
.publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__description {
  width: calc(100% - 50px);
  margin-top: 20px;
  overflow: auto !important;
  max-height: 170px;
  scrollbar-color: #00b3f0 #1d1d1d;
  scrollbar-width: thin;
}
.publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__description::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #1d1d1d;
}
.publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__description::-webkit-scrollbar {
  width: 6px;
  background-color: #1d1d1d;
}
.publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__description::-webkit-scrollbar-thumb {
  background-color: #00b3f0;
}
.publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__content {
  position: absolute;
  top: 50%;
  left: calc(100% - 30px);
  width: calc(100% - 50px);
  margin-top: 20px;
}

@media screen and (max-width: 1440px) {
  .publication-lightbox__container {
    width: 60%;
  }
}
@media screen and (max-width: 1050px) {
  .publication-lightbox__container {
    width: 75%;
  }
}
@media screen and (max-width: 800px) {
  .publication-lightbox {
    height: 100vh;
    overflow: scroll;
  }
  .publication-lightbox__container {
    position: absolute;
    width: 100%;
    padding: 60px 60px;
  }
}
@media screen and (max-width: 700px) {
  .publication-lightbox:not(.publication-lightbox--friends-of-museum) .publication-lightbox__container {
    flex-direction: column;
    align-items: center;
  }
  .publication-lightbox:not(.publication-lightbox--friends-of-museum) .publication-lightbox__image-box {
    margin-right: 0;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .publication-lightbox.publication-lightbox--friends-of-museum {
    width: 100%;
    margin-right: 0;
  }
  .publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__image-box {
    width: 100%;
    margin-right: 0;
  }
  .publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__image {
    width: 200px;
    margin: 0 auto;
  }
  .publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__title {
    width: 100%;
  }
  .publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__description {
    width: 100%;
  }
  .publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__content {
    position: relative;
    left: initial;
    top: initial;
    margin: 30px auto;
    display: block;
    text-align: center;
  }
  .publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__content .publication-lightbox__image {
    width: auto;
    margin: 0 auto;
  }
}
@media screen and (max-width: 500px) {
  .publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__container {
    padding: 30px 30px;
  }
  .publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__image {
    width: auto;
    max-width: 100%;
  }
  .publication-lightbox.publication-lightbox--friends-of-museum .publication-lightbox__content .publication-lightbox__image {
    width: 100%;
  }
}`;
    }

    connectedCallback() {
        let overlay = document.createElement('div');
        overlay.classList.add('publication-lightbox');

        this._closeClick = this._closeClick.bind(this);
        this._keyboard = this._keyboard.bind(this);
        this._overlayClick = this._overlayClick.bind(this);

        let closeButton = document.createElement('button');
        closeButton.classList.add('publication-lightbox__close-button');
        closeButton.setAttribute('aria-label', 'Zamknij modal');
        closeButton.innerHTML = '<div class="first-line"></div><div class="second-line"></div>';

        this.container.classList.add('publication-lightbox__container');
        this.container.setAttribute('role', 'dialog');
        this.container.setAttribute('aria-modal', true);
        this.container.setAttribute('aria-labelledby', this.labeledBy);

        overlay.append(closeButton);
        overlay.append(this.container);
        this.append(overlay);

        window.addEventListener('keyup', this._keyboard);
        overlay.addEventListener('click', this._overlayClick);

        closeButton.addEventListener('click', this._closeClick);
    }
    _closeClick(event) {
        event.preventDefault();
        this._closeModal();
    }

    _keyboard(event) {
        if(this.isOpen) {
            if(event.keyCode === 27) {
                this._closeModal();
            }
        }
    }

    _overlayClick(event) {
        if(this.isOpen) {
            if(event.target.classList.contains('publication-lightbox')) {
                this._closeModal();
            }
        }
    }

    _closeModal() {
        this.style.display = 'none';
        document.body.style.overflow = 'auto';
    }
    openModal() {
        document.body.style.overflow = 'hidden';
        // document.body.append(this);
        this.style.display = 'block';

        this.isOpen = true;
    }
}

let initElement = () => {
    customElements.define('nt-modal', ModalElement);
};

export default {
    'init': initElement
};