class publicationDetails {
    constructor(selector) {
        this.selector = selector;
        this.publicationID = this.selector.getAttribute('data-id');

        this._showDetails = this._showDetails.bind(this);

        this.modal = this._openModal();
        this.modal.style.display = 'none';

        document.body.append(this.modal);

        if(this.publicationID !== '' && this.publicationID !== null) {
            this.selector.addEventListener('click', this._showDetails);
        }
    }

    _showDetails (event) {
        event.preventDefault();
        this.modal.openModal();
        this._getPublicationData().then(data => {
            this.modal.showPublication(data);
        }).catch(error => {
            this.modal.showError(error);
        })
    }

    _openModal(){
        let modal = document.createElement('publication-lightbox');
        return modal;
    }
    _getPublicationData(){
        let url = '/wp-json/wp/v2/publication/'+this.publicationID+'?_embed';
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
}

export default publicationDetails;