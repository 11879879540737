class InteractiveIcon extends HTMLElement {
    constructor() {
        super();

        this._openModal = this._openModal.bind(this);

        this.link = document.createElement('a');
        this.link.classList.add('content-icons__icon');
        this.modal = null;

        let href = this.getAttribute('href');
        if(href !== null) this.link.href = href;

        this.action = this.getAttribute('data-action');
        if(this.action === 'modal'){
            this.modalContent = this.innerHTML;
            this.innerHTML = '';
            this.genID = Math.random().toString(16).slice(2);
            this.link.id = this.genID;
            this.link.addEventListener('click', this._openModal);
            this.link.setAttribute('aria-haspopup', 'dialog');
            this.modal = document.createElement('nt-modal');

            let data_content = document.createElement('div');
            data_content.classList.add('publication-lightbox__content');
            data_content.innerHTML = this.modalContent;

            this.modal.container.append(data_content);
            this.modal.labeledBy = this.genID;
            this.modal.style.display = 'none';
        }
        if(this.action === 'link') this.link.target = '_blank';

        let title = this.getAttribute('data-title');
        let icon = this.getAttribute('data-icon');

        if(icon !== null && icon !== '0') {
            let iconEl = document.createElement('img');
            iconEl.src = icon;
            this.link.append(iconEl);
        } else if(icon === null) {
            let icon = `<svg id="Warstwa_2" data-name="Warstwa 2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 169.29 235.36">
              <defs>
                <clipPath id="doc-clip">
                  <rect style="fill: none;" width="169.29" height="235.36"/>
                </clipPath>
              </defs>
              <g id="Warstwa_1-2" data-name="Warstwa 1">
                <g style="clip-path: url(#doc-clip);">
                  <path style="fill: var(--default-font-color);" d="M139.85,99.92H29.45c-2.76,0-5-2.24-5-5s2.24-5,5-5h110.4c2.76,0,5,2.24,5,5s-2.24,5-5,5"/>
                  <path style="stroke: var(--default-font-color); stroke-miterlimit: 10; stroke-width: 3px;fill: none;" d="M139.85,99.92H29.45c-2.76,0-5-2.24-5-5s2.24-5,5-5h110.4c2.76,0,5,2.24,5,5s-2.24,5-5,5Z"/>
                  <path style="fill: var(--default-font-color);" d="M139.85,131.18H29.45c-2.76,0-5-2.24-5-5s2.24-5,5-5h110.4c2.76,0,5,2.24,5,5s-2.24,5-5,5"/>
                  <path style="stroke: var(--default-font-color); stroke-miterlimit: 10; stroke-width: 3px;fill: none;" d="M139.85,131.18H29.45c-2.76,0-5-2.24-5-5s2.24-5,5-5h110.4c2.76,0,5,2.24,5,5s-2.24,5-5,5Z"/>
                  <path style="fill: var(--default-font-color);" d="M139.85,162.44H29.45c-2.76,0-5-2.24-5-5s2.24-5,5-5h110.4c2.76,0,5,2.24,5,5s-2.24,5-5,5"/>
                  <path style="stroke: var(--default-font-color); stroke-miterlimit: 10; stroke-width: 3px;fill: none;" d="M139.85,162.44H29.45c-2.76,0-5-2.24-5-5s2.24-5,5-5h110.4c2.76,0,5,2.24,5,5s-2.24,5-5,5Z"/>
                  <path style="stroke: var(--default-font-color); stroke-miterlimit: 10; stroke-width: 3px;fill: none;" d="M105.92,49.94V11.5H18.5c-3.86,0-7,3.14-7,7V216.86c0,3.86,3.14,7,7,7H150.79c3.86,0,7-3.14,7-7V63.45h-38.37c-7.45,0-13.5-6.06-13.5-13.5Z"/>
                  <path style="fill: var(--default-font-color);" d="M164.87,53.45L115.92,4.43l-2.93-2.93H18.5C9.13,1.5,1.5,9.13,1.5,18.5V216.86c0,9.37,7.63,17,17,17H150.79c9.37,0,17-7.63,17-17V56.38l-2.93-2.93ZM115.92,18.58l34.82,34.86h-31.31c-1.93,0-3.5-1.57-3.5-3.5V18.58Zm41.88,198.27c0,3.86-3.14,7-7,7H18.5c-3.86,0-7-3.14-7-7V18.5c0-3.86,3.14-7,7-7H105.92V49.94c0,7.45,6.06,13.5,13.5,13.5h38.37V216.86Z"/>
                  <path style="stroke: var(--default-font-color); stroke-miterlimit: 10; stroke-width: 3px;fill: none;" d="M164.87,53.45L115.92,4.43l-2.93-2.93H18.5C9.13,1.5,1.5,9.13,1.5,18.5V216.86c0,9.37,7.63,17,17,17H150.79c9.37,0,17-7.63,17-17V56.38l-2.93-2.93ZM115.92,18.58l34.82,34.86h-31.31c-1.93,0-3.5-1.57-3.5-3.5V18.58Zm41.88,198.27c0,3.86-3.14,7-7,7H18.5c-3.86,0-7-3.14-7-7V18.5c0-3.86,3.14-7,7-7H105.92V49.94c0,7.45,6.06,13.5,13.5,13.5h38.37V216.86Z"/>
                </g>
              </g>
            </svg>`;
            this.link.innerHTML += icon;
        }
        let titleEl = document.createElement('span');
        titleEl.innerText = title;
        this.link.append(titleEl);
        this.append(this.link);
        if(this.modal !== null) document.body.append(this.modal);
    }


    _openModal(event){
        event.preventDefault();

        this.modal.openModal();
    }
}

let initElement = () => {
    customElements.define('interactive-icon', InteractiveIcon);
};

export default {
    'init': initElement
};
