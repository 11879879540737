class TimelineLesson extends HTMLElement {
    constructor() {
        super();

        this._openModal = this._openModal.bind(this);

        let modalContent = this.querySelector('[data-element="modal-content"]');
        if(modalContent !== null) {
            this.modalContent = modalContent.innerHTML;
            modalContent.remove();
        }
        this.video = this.getAttribute('data-video');
        this.genID = Math.random().toString(16).slice(2);

        this.modal = document.createElement('nt-modal');

        let data_content = document.createElement('div');
        data_content.classList.add('publication-lightbox__content');
        data_content.innerHTML = this.modalContent;

        if(this.video !== null && this.video !== '') {
            let width = (window.innerWidth / 2 ) - 60;
            console.log(window.innerWidth, width);
            if(window.innerWidth < 800) {
                width = window.innerWidth - 120;
            } else if(window.innerWidth < 1050) {
                width = (window.innerWidth * 0.75 ) - 60;
            } else if(window.innerWidth < 1440) {
                width = (window.innerWidth * 0.6 ) - 60;
            }
            console.log(width);
            let height = (width / 16) * 9;

            let iframe = document.createElement('iframe');
            iframe.src = 'https://www.youtube.com/embed/' + this.video;
            iframe.width = width + 'px';
            iframe.height = height + 'px';
            iframe.frameBorder = '0';
            iframe.setAttribute('allowfullscreen', '');


            data_content.append(iframe);

        }

        this.modal.container.append(data_content);
        this.modal.style.display = 'none';
        this.modal.labeledBy = this.genID;
        this.id = this.genID;
        this.setAttribute('aria-haspopup', 'dialog');

        document.body.append(this.modal);

        this.addEventListener('click', this._openModal);
    }


    _openModal(event){
        event.preventDefault();

        this.modal.openModal();
    }
}

let initElement = () => {
    customElements.define('timeline-lesson', TimelineLesson);
};

export default {
    'init': initElement
};
